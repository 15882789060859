<template>
  <!-- confirm START -->
  <transition name="fade" mode="out-in">
    <div class="dialog dialog-confirm" v-if="loadingVisible">
      <div class="dialog-confirm-container">

        <div class="inner">
          <div class="icon"></div>
          <h4 v-html="headline" v-if="false"></h4>
          <div class="excerpt" v-html="excerpt"></div>

          <div class="dialog-footer">
            <div
              class="btn-inverted close"
              v-on:click="close()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
              v-html="$t('terminal.index.dialog.confirm.no')"
            ></div>
            <div
              class="btn-default confirm btn-default-blue"
              v-on:click="confirm()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
              v-html="$t('terminal.index.dialog.confirm.yes')"
            ></div>
          </div>

          <!-- cart:actions:checkout:abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
            v-if="false"
          >
            <span v-html="$t('terminal.index.cart.purchase.abort')"></span>
          </div>
          <!-- cart:actions:checkout:abort END -->
        </div>
      </div>
    </div>
  </transition>
  <!-- confirm END -->
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'dialog-confirm',
  props: {
    data: {
      type: Object,
    },

    loadingVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },

    open: {
      type: Function,
    },

    close: {
      type: Function,
    },

    confirm: {
      type: Function,
    },
    newMessage: {
      type: Number,
    },
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    async resetStoreCart() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },
  },
};
</script>
